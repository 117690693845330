import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Row, Col } from 'antd';

import './UnauthorizedLayout.scss';

import LoginPage from '../../pages/LoginPage/LoginPage';
import SignupPage from '../../pages/SignupPage/SignupPage';

const UnauthorizedLayout = () => (
  <div className="unauthorized-layout">
    {/*
    Imagine this could be a general layout for all unauthorized pages like
    the login page, forgot password, email-verified, etc...
    */}
    <div className="detail-text text-center">
      <Row className="detail-text-item">Selfb is a Trademark of Annovating B.V.</Row>
      <Row className="detail-text-item">
        Selfb is the independent, Netherlands based fitness tracker time series database for
        research and product development.
      </Row>
      <Row className="detail-text-item">
        Selfb is a Trademark of Annovating B.V with Chamber of Commerce subscription in the Netherlands. KvK 69005087, Btw 857687128B01.
      </Row>
      <Row className="detail-text-item">
        Annovating B.V develops smart machine learning software to reduce the societal burden of cardio vascular diseases.
      </Row>
      <Row className="detail-text-item">Bank account NL82TRIO0338411968.</Row>
    </div>
    <Switch>
      <Route path="/home/login" component={LoginPage} />
      <Route path="/home/signup" component={SignupPage} />
      <Redirect to="/home/login" />
    </Switch>
  </div>
);

export default UnauthorizedLayout;
