/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Icon, Input, Button, message } from 'antd';

import '../LoginForm/LoginForm.scss';
import { userResgistraionReq } from '../../services/api';

const FormItem = Form.Item;

class SignupForm extends React.Component {
  state = {
    signup: {},
    confirmDirty: false,
    isLoggingIn: false,
    isShowPassword: false
  };

  usernameInput = React.createRef();

  passwordInput = React.createRef();

  componentDidMount = () => {
    this.usernameInput.current.focus();
  };

  handleSubmit = e => {
    const { form } = this.props;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        this.signup(values.signup);
        this.setState({
          isLoggingIn: true
        });
      }
    });
  };

  signup = signup => {
    userResgistraionReq(signup)
      .then(response => this.handleSignupSuccess(response.data))
      .catch(error => this.handleSignupError(error.response));
  };

  handleSignupSuccess = data => {
    message.success('Your account has been created succesfully. Please login.');
    const { history } = this.props;
    history.push('/home/login');
    // console.log(data.key);
    // localStorage.setItem('auth', JSON.stringify(response.data));
  };

  handleSignupError = error => {
    this.setState({
      isLoggingIn: false
    });

    if (error && error.data && Object.keys(error.data).length) {
      message.error(error.data[Object.keys(error.data)[0]][0]);
    } else {
      message.error('Unfortunately there was an error, please try again.');
    }
    // if (error && error.data && error.data.error) {
    //   message.error(error.data.error);
    //   this.usernameInput.current.focus();
    // } else {
    //   message.error('Unfortunately there was an error, please try again');
    //   form.resetFields();
    // }
  };

  showHidePassword = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
    this.passwordInput.current.focus();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('signup.password1')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['signup.password2'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { isLoggingIn, isShowPassword } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator('signup.username', {
            rules: [{ required: true, message: 'Please enter your username!' }]
          })(
            <Input ref={this.usernameInput} placeholder="Username" autoFocus autoComplete="off" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('signup.email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required: true,
                message: 'Please input your E-mail!'
              }
            ]
          })(<Input placeholder="Email" autoFocus autoComplete="off" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('signup.password1', {
            rules: [
              {
                required: true,
                message: 'Please input your password!'
              },
              { min: 8, message: 'Password must be minimum 8 characters.' },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(
            <Input.Password
              type={isShowPassword ? 'text' : 'password'}
              placeholder="Password"
              autoComplete="off"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('signup.password2', {
            rules: [
              {
                required: true,
                message: 'Please confirm your password!'
              },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(
            <Input.Password
              type={isShowPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              autoComplete="off"
              onBlur={this.handleConfirmBlur}
            />
          )}
        </FormItem>
        <FormItem>
          {isLoggingIn && (
            <Button type="primary" htmlType="submit" className="login-form-button" disabled>
              <Icon type="sync" className="login-spinner-icon" spin />
            </Button>
          )}
          {!isLoggingIn && (
            <Button type="primary" htmlType="submit" className="login-form-button">
              SIGN UP
            </Button>
          )}
        </FormItem>
        <div className="signup-msg">
          <p>Do you have account?</p>
          <Link to="/home/login">LOGIN HERE</Link>
        </div>
      </Form>
    );
  }
}

SignupForm.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(Form.create()(SignupForm));
