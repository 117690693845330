/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint jsx-a11y/anchor-is-valid: [0] */
import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import './Nav.scss';

class Nav extends Component {
  state = { selectedMenuItem: '' };

  componentDidMount() {
    const { history } = this.props;

    this.setActiveMenuItem();

    this.historyUnlisten = history.listen((location, action) => {
      this.setActiveMenuItem();
    });
  }

  componentWillUnmount() {
    this.historyUnlisten();
  }

  setActiveMenuItem() {
    const match = matchPath(window.location.pathname, {
      path: '/:section',
      strict: false
    });

    if (match) {
      this.setState({ selectedMenuItem: match.params.section });
    }
  }

  signout = e => {
    const { history } = this.props;

    e.preventDefault();
    localStorage.removeItem('auth');
    localStorage.clear();
    history.push('/home/login');
  };

  render() {
    const { selectedMenuItem } = this.state;

    return (
      <>
        <Menu selectedKeys={[selectedMenuItem]} mode="horizontal" theme="light">
          {/* <Menu.Item key="dsm-search">
            <Link to="/dsm-search">DSM Search</Link>
          </Menu.Item> */}
          <Menu.Item key="dsm-logout">
            <a onClick={e => this.signout(e)}>Logout</a>
          </Menu.Item>
        </Menu>
      </>
    );
  }
}

Nav.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Nav);
