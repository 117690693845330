import React from 'react';
import SignupForm from '../../components/SignupForm/SignupForm';

import '../LoginPage/LoginPage.scss';

function SignupPage() {
  return (
    <div className="login-page">
      <div className="brand" href="/">
        <div className="logo" />
      </div>
      <SignupForm />
    </div>
  );
}

export default SignupPage;
